import { FC, useState, SyntheticEvent, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material";
import { InputAdornment } from "@mui/material";

export type nameIdType = {
  inputValue?: string;
  name: string;
  id?: string | number;
};

const filter = createFilterOptions<nameIdType>();

type AutocompleteWithAddPropsType = {
  onChange: (value: string | nameIdType | null) => void;
  options: readonly nameIdType[];
  label?: string;
  initValue?: string | nameIdType | null;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
};

const AutocompleteWithAdd: FC<AutocompleteWithAddPropsType> = ({
  onChange,
  options,
  label = "Select",
  initValue = null,
  placeholder = "",
  startAdornment,
  disabled = false,
  required = false,
  error = false,
  helperText,
}) => {
  const [value, setValue] = useState<nameIdType | null>(
    initValue
      ? typeof initValue === "string"
        ? { name: initValue }
        : initValue
      : null,
  );

  useEffect(() => {
    if (initValue === null) {
      setValue(null);
    } else if (typeof initValue === "string") {
      setValue({ name: initValue });
    } else if (initValue !== value) {
      setValue(initValue);
    }
  }, [initValue]);

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: string | nameIdType | null,
  ) => {
    if (typeof newValue === "string") {
      const newOption = { name: newValue };
      setValue(newOption);
      onChange(newOption);
    } else if (newValue && newValue.inputValue) {
      const newOption = { name: newValue.inputValue };
      setValue(newOption);
      onChange(newOption);
    } else {
      setValue(newValue);
      onChange(newValue);
    }
  };

  const handleInputChange = (
    _event: React.SyntheticEvent,
    newInputValue: string,
  ) => {
    if (newInputValue === "") {
      onChange(null);
    }
  };

  const filterOptions = (
    options: nameIdType[],
    params: FilterOptionsState<nameIdType>,
  ) => {
    const filtered = filter(options, params);
    const { inputValue } = params;

    if (inputValue !== "") {
      const isExisting = options.some(
        (option) => inputValue.toLowerCase() === option.name.toLowerCase(),
      );

      if (!isExisting) {
        filtered.push({
          inputValue,
          name: `Add "${inputValue}"`,
        });
      }
    }

    return filtered;
  };

  const getOptionLabel = (option: nameIdType | string) => {
    if (typeof option === "string") {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.name;
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={`autocomplete-${label}`}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          required={required}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startAdornment && (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteWithAdd;
