import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "../../utils";
import { EraScore } from "../../types";

export function buildMainTableHead() {
  return [
    [
      { content: "" },
      { content: "Back", colSpan: 2 },
      { content: "Neck", colSpan: 2 },
      { content: "Shoulder" },
      { content: "L" },
      { content: "R" },
      { content: "Elbow" },
      { content: "L" },
      { content: "R" },
      { content: "Wrist" },
      { content: "L" },
      { content: "R" },
    ],
  ];
}

export function buildPostureRow(eraDto: EraDto) {
  return [
    { content: "Posture" },
    { content: "Flexed>20°\nExtended\nSideways>20°\nTwisted>20°" },
    {
      content: [
        boolToScore(eraDto.back.posture.flexedOver20),
        boolToScore(eraDto.back.posture.extended),
        boolToScore(eraDto.back.posture.sidewaysOver20),
        boolToScore(eraDto.back.posture.twistedOver20),
      ].join("\n"),
    },
    { content: "Flexed>30°\nExtended\nSideways\nTwisted" },
    {
      content: [
        boolToScore(eraDto.neck.posture.flexedOver30),
        boolToScore(eraDto.neck.posture.extended),
        boolToScore(eraDto.neck.posture.sideways),
        boolToScore(eraDto.neck.posture.twisted),
      ].join("\n"),
    },
    { content: "Flexed>45°\nExtended\nAbducted>45°" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.posture.flexedOver45),
        boolToScore(eraDto.leftShoulder.posture.extended),
        boolToScore(eraDto.leftShoulder.posture.abductedOver45),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.posture.flexedOver45),
        boolToScore(eraDto.rightShoulder.posture.extended),
        boolToScore(eraDto.rightShoulder.posture.abductedOver45),
      ].join("\n"),
    },
    { content: "Flexed<60°\nFlexed>100°\nForearm Rotated" },
    {
      content: [
        boolToScore(eraDto.leftElbow.posture.flexedUnder60),
        boolToScore(eraDto.leftElbow.posture.flexedOver100),
        boolToScore(eraDto.leftElbow.posture.forearmRotated),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightElbow.posture.flexedUnder60),
        boolToScore(eraDto.rightElbow.posture.flexedOver100),
        boolToScore(eraDto.rightElbow.posture.forearmRotated),
      ].join("\n"),
    },
    {
      content:
        "Flexed>45°\nExtended>45°\nRadial Deviated>15°\nUlnar Deviated>20°",
    },
    {
      content: [
        boolToScore(eraDto.leftWrist.posture.flexedOver45),
        boolToScore(eraDto.leftWrist.posture.extendedOver45),
        boolToScore(eraDto.leftWrist.posture.radialDeviatedOver15),
        boolToScore(eraDto.leftWrist.posture.ulnarDeviatedOver20),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.posture.flexedOver45),
        boolToScore(eraDto.rightWrist.posture.extendedOver45),
        boolToScore(eraDto.rightWrist.posture.radialDeviatedOver15),
        boolToScore(eraDto.rightWrist.posture.ulnarDeviatedOver20),
      ].join("\n"),
    },
  ];
}

export function buildSevereRow(eraDto: EraDto) {
  return [
    { content: "Severe\nPosture" },
    { content: "Flexed>45°\nExtended>20°" },
    {
      content: [
        boolToScore(eraDto.back.severePosture.flexedOver45),
        boolToScore(eraDto.back.severePosture.extendedOver20),
      ].join("\n"),
    },
    { content: "Flexed>60°\nExtended>30°" },
    {
      content: [
        boolToScore(eraDto.neck.severePosture.flexedOver60),
        boolToScore(eraDto.neck.severePosture.extendedOver30),
      ].join("\n"),
    },
    { content: "Flexed>90°\nAbducted>90°" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.severePosture.flexedOver90),
        boolToScore(eraDto.leftShoulder.severePosture.abductedOver90),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.severePosture.flexedOver90),
        boolToScore(eraDto.rightShoulder.severePosture.abductedOver90),
      ].join("\n"),
    },
    { content: "Forearm\nRotated>60°" },
    {
      content: boolToScore(eraDto.leftElbow.severePosture.forearmRotatedOver60),
    },
    {
      content: boolToScore(
        eraDto.rightElbow.severePosture.forearmRotatedOver60,
      ),
    },
    { content: "Flexed>65°\nExtended>65°" },
    {
      content: [
        boolToScore(eraDto.leftWrist.severePosture.flexedOver65),
        boolToScore(eraDto.leftWrist.severePosture.extendedOver65),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.severePosture.flexedOver65),
        boolToScore(eraDto.rightWrist.severePosture.extendedOver65),
      ].join("\n"),
    },
  ];
}

export function buildForceRow(eraDto: EraDto) {
  return [
    { content: "Force" },
    { content: ">10 lb" },
    { content: boolToScore(eraDto.back.force.over10lb, 2) },
    { content: "N/A" },
    { content: "-" },
    { content: ">10 lb 1-hand\n>15 lb 2-hand" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.force.over10lb1Hand, 2),
        boolToScore(eraDto.leftShoulder.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.force.over10lb1Hand, 2),
        boolToScore(eraDto.rightShoulder.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    { content: ">10 lb 1-hand\n>15 lb 2-hand" },
    {
      content: [
        boolToScore(eraDto.leftElbow.force.over10lb1Hand, 2),
        boolToScore(eraDto.leftElbow.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightElbow.force.over10lb1Hand, 2),
        boolToScore(eraDto.rightElbow.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    { content: "Pinch Grip>2 lb\nPower Grip>10 lb" },
    {
      content: [
        boolToScore(eraDto.leftWrist.force.pinchGripOver2lb, 2),
        boolToScore(eraDto.leftWrist.force.powerGripOver10lb, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.force.pinchGripOver2lb, 2),
        boolToScore(eraDto.rightWrist.force.powerGripOver10lb, 2),
      ].join("\n"),
    },
  ];
}

export function buildRepetitionRow(eraDto: EraDto) {
  return [
    { content: "Repetition" },
    { content: ">2/min" },
    { content: boolToScore(eraDto.back.repetition.over2PerMin, 2) },
    { content: ">3/min" },
    { content: boolToScore(eraDto.neck.repetition.over3PerMin, 2) },
    { content: ">2.5/min" },
    {
      content: boolToScore(eraDto.leftShoulder.repetition.over2Point5PerMin, 2),
    },
    {
      content: boolToScore(
        eraDto.rightShoulder.repetition.over2Point5PerMin,
        2,
      ),
    },
    { content: ">10/min" },
    { content: boolToScore(eraDto.leftElbow.repetition.over10PerMin, 2) },
    { content: boolToScore(eraDto.rightElbow.repetition.over10PerMin, 2) },
    { content: ">15/min" },
    { content: boolToScore(eraDto.leftWrist.repetition.over15PerMin, 2) },
    { content: boolToScore(eraDto.rightWrist.repetition.over15PerMin, 2) },
  ];
}

export function buildStaticRow(eraDto: EraDto) {
  return [
    { content: "Static\nExertion" },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.back.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.neck.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftShoulder.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightShoulder.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftElbow.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightElbow.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftWrist.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightWrist.staticExertion.over10Sec) },
  ];
}

export function buildTotalRow(scores: EraScore) {
  return [
    { content: "TOTAL" },
    { content: "" },
    { content: scores.backScore.toString() },
    { content: "" },
    { content: scores.neckScore.toString() },
    { content: "" },
    { content: scores.leftShoulderScore.toString() },
    { content: scores.rightShoulderScore.toString() },
    { content: "" },
    { content: scores.leftElbowScore.toString() },
    { content: scores.rightElbowScore.toString() },
    { content: "" },
    { content: scores.leftWristScore.toString() },
    { content: scores.rightWristScore.toString() },
  ];
}

export function buildMainTableData(eraDto: EraDto, scores: EraScore) {
  return {
    mainTableHead: buildMainTableHead(),
    mainTableBody: [
      buildPostureRow(eraDto),
      buildSevereRow(eraDto),
      buildForceRow(eraDto),
      buildRepetitionRow(eraDto),
      buildStaticRow(eraDto),
      buildTotalRow(scores),
    ],
  };
}
