import { EraDto } from "src/app/services/generatedApi";
import { setupPdfDocument } from "./setupPdf";
import { generateJobInfo } from "./sections/jobInfo";
import { generateMainTable } from "./sections/mainTable";
import { generateAccelerators } from "./sections/accelerators";
import { generateRiskRating } from "./sections/riskRating";
import { generateOverallScore } from "./sections/overallScore";
import { calculateEraScore } from "../../score";
import { TablePosition } from "./types";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => TablePosition;
    lastAutoTable: TablePosition;
  }
}

export const generateEraReport = (eraDto: EraDto) => {
  try {
    const scores = calculateEraScore(eraDto);
    let doc = setupPdfDocument();
    doc = generateJobInfo(doc, eraDto);
    doc = generateMainTable(doc, eraDto, scores);
    doc = generateAccelerators(doc, eraDto);
    doc = generateRiskRating(doc);
    doc = generateOverallScore(doc, scores);

    return doc;
  } catch (error) {
    console.error("Error generating ERA report:", error);
    throw error;
  }
};
