import { EraDto, ResultRo } from "src/app/services/generatedApi";
import { defaultEraData } from "./constants";
import { Angle3DResultRo } from "src/types/pose";

const getPostureScore = (
  angle: number,
  bodyPart: "back" | "neck" | "shoulder" | "elbow" | "wrist",
): number => {
  switch (bodyPart) {
    case "back":
      if (angle > 45) return 2;
      if (angle > 20) return 1;
      if (angle < -20) return 2;
      if (angle < 0) return 1;
      return 0;
    case "neck":
      if (angle > 60) return 2;
      if (angle > 30) return 1;
      if (angle < -30) return 2;
      if (angle < 0) return 1;
      return 0;
    case "shoulder":
      if (angle > 90) return 2;
      if (angle > 45) return 1;
      if (angle < 0) return 1;
      return 0;
    case "elbow":
      if (angle > 100) return 1;
      if (angle < 60) return 1;
      return 0;
    case "wrist":
      if (angle > 65) return 2;
      if (angle > 45) return 1;
      if (angle < -65) return 2;
      if (angle < -45) return 1;
      return 0;
    default:
      return 0;
  }
};

interface MovementData {
  lastValue: number;
  repetitionCount: number;
  staticDuration: number;
  isInScoringPosition: boolean;
  timeExceedingThreshold: number;
  maxStaticDuration: number;
  score: number;
}

const initializeMovementData = (): MovementData => ({
  lastValue: 0,
  repetitionCount: 0,
  staticDuration: 0,
  isInScoringPosition: false,
  timeExceedingThreshold: 0,
  maxStaticDuration: 0,
  score: 0,
});

const trackMovement = (
  current: number | undefined,
  bodyPart: "back" | "neck" | "shoulder" | "elbow" | "wrist",
  data: MovementData,
  frameTime: number,
): MovementData => {
  if (current === undefined) return data;

  const newData = { ...data };
  const currentScore = getPostureScore(current, bodyPart);
  const previousScore = getPostureScore(data.lastValue, bodyPart);

  if (currentScore > 0 && previousScore === 0) {
    newData.repetitionCount++;
    newData.isInScoringPosition = true;
  } else if (currentScore === 0) {
    newData.isInScoringPosition = false;
  }

  if (currentScore > 0) {
    newData.timeExceedingThreshold += frameTime;
  } else {
    newData.timeExceedingThreshold = 0;
  }

  newData.maxStaticDuration = Math.max(
    newData.maxStaticDuration,
    newData.timeExceedingThreshold,
  );
  newData.score = Math.max(currentScore, data.score);

  newData.lastValue = current;
  return newData;
};

export const calculateEraData = (
  fps: number,
  result: ResultRo[],
  selectedAngles3D?: Angle3DResultRo[],
  isSited: boolean = false,
  isAdmin: boolean = false,
): EraDto => {
  const STATIC_TIME_THRESHOLD = 10;
  const frameTime = 1 / fps;

  const eraData: EraDto = { ...defaultEraData };
  if (!result.length) return eraData;

  const movements = {
    back: initializeMovementData(),
    neck: initializeMovementData(),
    leftShoulder: initializeMovementData(),
    rightShoulder: initializeMovementData(),
    leftElbow: initializeMovementData(),
    rightElbow: initializeMovementData(),
    leftWrist: initializeMovementData(),
    rightWrist: initializeMovementData(),
  };

  result.forEach((frame, index) => {
    const angles3D = selectedAngles3D?.[index];

    if (frame.back !== undefined) {
      if (isAdmin) {
        movements.back = trackMovement(
          frame.back,
          "back",
          movements.back,
          frameTime,
        );
      }

      if (frame.back > 20) eraData.back.posture.flexedOver20 = true;
      if (frame.back < 0) eraData.back.posture.extended = true;
      if (frame.back > 45) eraData.back.severePosture.flexedOver45 = true;
      if (frame.back < -20) eraData.back.severePosture.extendedOver20 = true;

      if (angles3D?.back) {
        if (Math.abs(angles3D.back.Beta) > 20) {
          eraData.back.posture.sidewaysOver20 = true;
        }
        if (Math.abs(angles3D.back.Gamma) > 20) {
          eraData.back.posture.twistedOver20 = true;
        }
      }
    }

    if (frame.neck !== undefined) {
      if (isAdmin) {
        movements.neck = trackMovement(
          frame.neck,
          "neck",
          movements.neck,
          frameTime,
        );
      }

      if (frame.neck > 30) eraData.neck.posture.flexedOver30 = true;
      if (frame.neck < 0) eraData.neck.posture.extended = true;
      if (frame.neck > 60) eraData.neck.severePosture.flexedOver60 = true;
      if (frame.neck < -30) eraData.neck.severePosture.extendedOver30 = true;

      if (angles3D?.neck) {
        if (Math.abs(angles3D.neck.Beta) > 10) {
          eraData.neck.posture.sideways = true;
        }
        if (Math.abs(angles3D.neck.Gamma) > 10) {
          eraData.neck.posture.twisted = true;
        }
      }
    }

    if (frame.leftUpperArm !== undefined) {
      if (isAdmin) {
        movements.leftShoulder = trackMovement(
          frame.leftUpperArm,
          "shoulder",
          movements.leftShoulder,
          frameTime,
        );
      }

      if (frame.leftUpperArm > 45)
        eraData.leftShoulder.posture.flexedOver45 = true;
      if (frame.leftUpperArm < 0) eraData.leftShoulder.posture.extended = true;
      if (frame.leftUpperArm > 90)
        eraData.leftShoulder.severePosture.flexedOver90 = true;

      if (angles3D?.leftUpperArm) {
        if (Math.abs(angles3D.leftUpperArm.Beta) > 45) {
          eraData.leftShoulder.posture.abductedOver45 = true;
        }
        if (Math.abs(angles3D.leftUpperArm.Beta) > 90) {
          eraData.leftShoulder.severePosture.abductedOver90 = true;
        }
      }
    }

    if (frame.rightUpperArm !== undefined) {
      if (isAdmin) {
        movements.rightShoulder = trackMovement(
          frame.rightUpperArm,
          "shoulder",
          movements.rightShoulder,
          frameTime,
        );
      }

      if (frame.rightUpperArm > 45)
        eraData.rightShoulder.posture.flexedOver45 = true;
      if (frame.rightUpperArm < 0)
        eraData.rightShoulder.posture.extended = true;
      if (frame.rightUpperArm > 90)
        eraData.rightShoulder.severePosture.flexedOver90 = true;

      if (angles3D?.rightUpperArm) {
        if (Math.abs(angles3D.rightUpperArm.Beta) > 45) {
          eraData.rightShoulder.posture.abductedOver45 = true;
        }
        if (Math.abs(angles3D.rightUpperArm.Beta) > 90) {
          eraData.rightShoulder.severePosture.abductedOver90 = true;
        }
      }
    }

    if (frame.leftLowerArm !== undefined) {
      if (isAdmin) {
        movements.leftElbow = trackMovement(
          frame.leftLowerArm,
          "elbow",
          movements.leftElbow,
          frameTime,
        );
      }

      if (frame.leftLowerArm < 60)
        eraData.leftElbow.posture.flexedUnder60 = true;
      if (frame.leftLowerArm > 100)
        eraData.leftElbow.posture.flexedOver100 = true;

      if (angles3D?.leftWrist?.Gamma) {
        if (Math.abs(angles3D.leftWrist.Gamma) > 45) {
          eraData.leftElbow.posture.forearmRotated = true;
        }
        if (Math.abs(angles3D.leftWrist.Gamma) > 60) {
          eraData.leftElbow.severePosture.forearmRotatedOver60 = true;
        }
      }
    }

    if (frame.rightLowerArm !== undefined) {
      if (isAdmin) {
        movements.rightElbow = trackMovement(
          frame.rightLowerArm,
          "elbow",
          movements.rightElbow,
          frameTime,
        );
      }

      if (frame.rightLowerArm < 60)
        eraData.rightElbow.posture.flexedUnder60 = true;
      if (frame.rightLowerArm > 100)
        eraData.rightElbow.posture.flexedOver100 = true;

      if (angles3D?.rightWrist?.Gamma) {
        if (Math.abs(angles3D.rightWrist.Gamma) > 45) {
          eraData.rightElbow.posture.forearmRotated = true;
        }
        if (Math.abs(angles3D.rightWrist.Gamma) > 60) {
          eraData.rightElbow.severePosture.forearmRotatedOver60 = true;
        }
      }
    }

    if (frame.leftWrist !== undefined) {
      if (isAdmin) {
        movements.leftWrist = trackMovement(
          frame.leftWrist,
          "wrist",
          movements.leftWrist,
          frameTime,
        );
      }

      if (frame.leftWrist > 45) eraData.leftWrist.posture.flexedOver45 = true;
      if (frame.leftWrist < -45)
        eraData.leftWrist.posture.extendedOver45 = true;
      if (frame.leftWrist > 65)
        eraData.leftWrist.severePosture.flexedOver65 = true;
      if (frame.leftWrist < -65)
        eraData.leftWrist.severePosture.extendedOver65 = true;

      if (angles3D?.leftWrist) {
        if (angles3D.leftWrist.Beta > 15) {
          eraData.leftWrist.posture.radialDeviatedOver15 = true;
        }
        if (angles3D.leftWrist.Beta < -20) {
          eraData.leftWrist.posture.ulnarDeviatedOver20 = true;
        }
      }
    }

    if (frame.rightWrist !== undefined) {
      if (isAdmin) {
        movements.rightWrist = trackMovement(
          frame.rightWrist,
          "wrist",
          movements.rightWrist,
          frameTime,
        );
      }

      if (frame.rightWrist > 45) eraData.rightWrist.posture.flexedOver45 = true;
      if (frame.rightWrist < -45)
        eraData.rightWrist.posture.extendedOver45 = true;
      if (frame.rightWrist > 65)
        eraData.rightWrist.severePosture.flexedOver65 = true;
      if (frame.rightWrist < -65)
        eraData.rightWrist.severePosture.extendedOver65 = true;

      if (angles3D?.rightWrist) {
        if (angles3D.rightWrist.Beta > 15) {
          eraData.rightWrist.posture.radialDeviatedOver15 = true;
        }
        if (angles3D.rightWrist.Beta < -20) {
          eraData.rightWrist.posture.ulnarDeviatedOver20 = true;
        }
      }
    }
  });

  if (isAdmin) {
    const minutesElapsed = result.length / (fps * 60);

    eraData.back.repetition.over2PerMin =
      movements.back.repetitionCount / minutesElapsed > 2;
    eraData.back.staticExertion.over10Sec =
      movements.back.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.neck.repetition.over3PerMin =
      movements.neck.repetitionCount / minutesElapsed > 3;
    eraData.neck.staticExertion.over10Sec =
      movements.neck.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.leftShoulder.repetition.over2Point5PerMin =
      movements.leftShoulder.repetitionCount / minutesElapsed > 2.5;
    eraData.leftShoulder.staticExertion.over10Sec =
      movements.leftShoulder.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.rightShoulder.repetition.over2Point5PerMin =
      movements.rightShoulder.repetitionCount / minutesElapsed > 2.5;
    eraData.rightShoulder.staticExertion.over10Sec =
      movements.rightShoulder.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.leftElbow.repetition.over10PerMin =
      movements.leftElbow.repetitionCount / minutesElapsed > 10;
    eraData.leftElbow.staticExertion.over10Sec =
      movements.leftElbow.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.rightElbow.repetition.over10PerMin =
      movements.rightElbow.repetitionCount / minutesElapsed > 10;
    eraData.rightElbow.staticExertion.over10Sec =
      movements.rightElbow.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.leftWrist.repetition.over15PerMin =
      movements.leftWrist.repetitionCount / minutesElapsed > 15;
    eraData.leftWrist.staticExertion.over10Sec =
      movements.leftWrist.maxStaticDuration >= STATIC_TIME_THRESHOLD;

    eraData.rightWrist.repetition.over15PerMin =
      movements.rightWrist.repetitionCount / minutesElapsed > 15;
    eraData.rightWrist.staticExertion.over10Sec =
      movements.rightWrist.maxStaticDuration >= STATIC_TIME_THRESHOLD;
  }

  return eraData;
};
