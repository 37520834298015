import { EraDto } from "src/app/services/generatedApi";
import { calculateEraScore } from "../../score";

export const generateEraCsvReport = (eraDto: EraDto): string => {
  try {
    const scores = calculateEraScore(eraDto);

    let csvContent = "ERA Assessment Report\n\n";

    csvContent += "Job Information\n";
    csvContent += `Job Name,${eraDto.jobInfo.jobName || ""}\n`;
    csvContent += `Department,${eraDto.jobInfo.department || ""}\n`;
    csvContent += `Assessor,${eraDto.jobInfo.assessor || ""}\n`;
    csvContent += `Date,${eraDto.jobInfo.date ? new Date(eraDto.jobInfo.date).toLocaleDateString() : ""}\n`;
    csvContent += `Shift,${eraDto.jobInfo.shift || ""}\n`;
    csvContent += `Cycle Time,${eraDto.jobInfo.cycleTime || ""}\n\n`;

    csvContent += "Risk Factor Assessment\n";
    csvContent +=
      "Section,Factor,Back,Neck,Left Shoulder,Right Shoulder,Left Elbow,Right Elbow,Left Wrist,Right Wrist\n";

    csvContent += `Posture,Flexed,${eraDto.back.posture.flexedOver20 ? "Yes" : "No"},${eraDto.neck.posture.flexedOver30 ? "Yes" : "No"},${eraDto.leftShoulder.posture.flexedOver45 ? "Yes" : "No"},${eraDto.rightShoulder.posture.flexedOver45 ? "Yes" : "No"},${eraDto.leftElbow.posture.flexedUnder60 || eraDto.leftElbow.posture.flexedOver100 ? "Yes" : "No"},${eraDto.rightElbow.posture.flexedUnder60 || eraDto.rightElbow.posture.flexedOver100 ? "Yes" : "No"},${eraDto.leftWrist.posture.flexedOver45 ? "Yes" : "No"},${eraDto.rightWrist.posture.flexedOver45 ? "Yes" : "No"}\n`;

    csvContent += `Posture,Extended,${eraDto.back.posture.extended ? "Yes" : "No"},${eraDto.neck.posture.extended ? "Yes" : "No"},${eraDto.leftShoulder.posture.extended ? "Yes" : "No"},${eraDto.rightShoulder.posture.extended ? "Yes" : "No"},,,,${eraDto.leftWrist.posture.extendedOver45 ? "Yes" : "No"},${eraDto.rightWrist.posture.extendedOver45 ? "Yes" : "No"}\n`;

    csvContent += `Posture,Sideways/Abducted,${eraDto.back.posture.sidewaysOver20 ? "Yes" : "No"},${eraDto.neck.posture.sideways ? "Yes" : "No"},${eraDto.leftShoulder.posture.abductedOver45 ? "Yes" : "No"},${eraDto.rightShoulder.posture.abductedOver45 ? "Yes" : "No"},,,${eraDto.leftWrist.posture.radialDeviatedOver15 || eraDto.leftWrist.posture.ulnarDeviatedOver20 ? "Yes" : "No"},${eraDto.rightWrist.posture.radialDeviatedOver15 || eraDto.rightWrist.posture.ulnarDeviatedOver20 ? "Yes" : "No"}\n`;

    csvContent += `Posture,Twisted/Rotated,${eraDto.back.posture.twistedOver20 ? "Yes" : "No"},${eraDto.neck.posture.twisted ? "Yes" : "No"},,,${eraDto.leftElbow.posture.forearmRotated ? "Yes" : "No"},${eraDto.rightElbow.posture.forearmRotated ? "Yes" : "No"},,\n`;

    csvContent += `Severe Posture,Severe Flexion,${eraDto.back.severePosture.flexedOver45 ? "Yes" : "No"},${eraDto.neck.severePosture.flexedOver60 ? "Yes" : "No"},${eraDto.leftShoulder.severePosture.flexedOver90 ? "Yes" : "No"},${eraDto.rightShoulder.severePosture.flexedOver90 ? "Yes" : "No"},,,${eraDto.leftWrist.severePosture.flexedOver65 ? "Yes" : "No"},${eraDto.rightWrist.severePosture.flexedOver65 ? "Yes" : "No"}\n`;

    csvContent += `Severe Posture,Severe Extension,${eraDto.back.severePosture.extendedOver20 ? "Yes" : "No"},${eraDto.neck.severePosture.extendedOver30 ? "Yes" : "No"},,,,,${eraDto.leftWrist.severePosture.extendedOver65 ? "Yes" : "No"},${eraDto.rightWrist.severePosture.extendedOver65 ? "Yes" : "No"}\n`;

    csvContent += `Severe Posture,Severe Abduction,,,${eraDto.leftShoulder.severePosture.abductedOver90 ? "Yes" : "No"},${eraDto.rightShoulder.severePosture.abductedOver90 ? "Yes" : "No"},,,,\n`;

    csvContent += `Severe Posture,Severe Rotation,,,,,${eraDto.leftElbow.severePosture.forearmRotatedOver60 ? "Yes" : "No"},${eraDto.rightElbow.severePosture.forearmRotatedOver60 ? "Yes" : "No"},,\n`;

    csvContent += `Force,Weight/Force,${eraDto.back.force.over10lb ? "Yes" : "No"},,${eraDto.leftShoulder.force.over10lb1Hand || eraDto.leftShoulder.force.over15lb2Hand ? "Yes" : "No"},${eraDto.rightShoulder.force.over10lb1Hand || eraDto.rightShoulder.force.over15lb2Hand ? "Yes" : "No"},${eraDto.leftElbow.force.over10lb1Hand || eraDto.leftElbow.force.over15lb2Hand ? "Yes" : "No"},${eraDto.rightElbow.force.over10lb1Hand || eraDto.rightElbow.force.over15lb2Hand ? "Yes" : "No"},${eraDto.leftWrist.force.pinchGripOver2lb || eraDto.leftWrist.force.powerGripOver10lb ? "Yes" : "No"},${eraDto.rightWrist.force.pinchGripOver2lb || eraDto.rightWrist.force.powerGripOver10lb ? "Yes" : "No"}\n`;

    csvContent += `Repetition,Frequency,${eraDto.back.repetition.over2PerMin ? "Yes" : "No"},${eraDto.neck.repetition.over3PerMin ? "Yes" : "No"},${eraDto.leftShoulder.repetition.over2Point5PerMin ? "Yes" : "No"},${eraDto.rightShoulder.repetition.over2Point5PerMin ? "Yes" : "No"},${eraDto.leftElbow.repetition.over10PerMin ? "Yes" : "No"},${eraDto.rightElbow.repetition.over10PerMin ? "Yes" : "No"},${eraDto.leftWrist.repetition.over15PerMin ? "Yes" : "No"},${eraDto.rightWrist.repetition.over15PerMin ? "Yes" : "No"}\n`;

    csvContent += `Static Exertion,>10 Seconds,${eraDto.back.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.neck.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.leftShoulder.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.rightShoulder.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.leftElbow.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.rightElbow.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.leftWrist.staticExertion.over10Sec ? "Yes" : "No"},${eraDto.rightWrist.staticExertion.over10Sec ? "Yes" : "No"}\n\n`;

    csvContent += "Accelerators\n";
    csvContent += "Factor,Present,Body Part Affected,Task\n";
    csvContent += `Vibration,${eraDto.accelerators.vibration ? "Yes" : "No"},${eraDto.accelerators.vibration?.bodyPartAffected || ""},${eraDto.accelerators.vibration?.task || ""}\n`;
    csvContent += `Contact Stress,${eraDto.accelerators.contactStress ? "Yes" : "No"},${eraDto.accelerators.contactStress?.bodyPartAffected || ""},${eraDto.accelerators.contactStress?.task || ""}\n`;
    csvContent += `Impact Stress,${eraDto.accelerators.impactStress ? "Yes" : "No"},${eraDto.accelerators.impactStress?.bodyPartAffected || ""},${eraDto.accelerators.impactStress?.task || ""}\n\n`;

    csvContent += "Assessment Scores\n";
    csvContent += "Body Part,Score,Risk Level\n";
    csvContent += `Back,${scores.backScore},${getRiskLevel(scores.backScore)}\n`;
    csvContent += `Neck,${scores.neckScore},${getRiskLevel(scores.neckScore)}\n`;
    csvContent += `Left Shoulder,${scores.leftShoulderScore},${getRiskLevel(scores.leftShoulderScore)}\n`;
    csvContent += `Right Shoulder,${scores.rightShoulderScore},${getRiskLevel(scores.rightShoulderScore)}\n`;
    csvContent += `Left Elbow,${scores.leftElbowScore},${getRiskLevel(scores.leftElbowScore)}\n`;
    csvContent += `Right Elbow,${scores.rightElbowScore},${getRiskLevel(scores.rightElbowScore)}\n`;
    csvContent += `Left Wrist,${scores.leftWristScore},${getRiskLevel(scores.leftWristScore)}\n`;
    csvContent += `Right Wrist,${scores.rightWristScore},${getRiskLevel(scores.rightWristScore)}\n`;
    csvContent += `Accelerators,${scores.acceleratorScore},${getRiskLevel(scores.acceleratorScore)}\n`;
    csvContent += `Overall Total,${scores.totalScore},${scores.riskLevel}\n`;

    return csvContent;
  } catch (error) {
    console.error("Error generating ERA CSV report:", error);
    throw error;
  }
};

const getRiskLevel = (score: number): string => {
  if (score <= 2) return "Low";
  if (score <= 5) return "Moderate";
  if (score <= 8) return "High";
  return "Very High";
};
