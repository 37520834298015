import { jsPDF } from "jspdf";
import { EraDto } from "src/app/services/generatedApi";
import { COLORS, TABLE_STYLES } from "../constants";
import { addSectionTitle } from "../utils";

export const generateAccelerators = (doc: jsPDF, eraDto: EraDto) => {
  const yPos = addSectionTitle(
    doc,
    "Step 2: Identify accelerators to WMSDs",
    doc.lastAutoTable.finalY + 11,
  );

  doc.setFontSize(8);
  doc.setFont("roboto", "italic");
  doc.text(
    "Accelerators are factors that can increase the risk of developing work-related musculoskeletal disorders.",
    15,
    yPos + 2,
  );

  const acceleratorsHead = [
    ["Accelerators:", "Y/N:", "Body Part Affected:", "Task:", "Score"],
  ];

  const acceleratorsBody = [
    [
      "Vibration",
      eraDto.accelerators?.vibration ? "Y" : "N",
      eraDto.accelerators?.vibration?.bodyPartAffected || "",
      eraDto.accelerators?.vibration?.task || "",
      eraDto.accelerators?.vibration ? "1" : "",
    ],
    [
      "Contact Stress",
      eraDto.accelerators?.contactStress ? "Y" : "N",
      eraDto.accelerators?.contactStress?.bodyPartAffected || "",
      eraDto.accelerators?.contactStress?.task || "",
      eraDto.accelerators?.contactStress ? "1" : "",
    ],
    [
      "Impact Stress",
      eraDto.accelerators?.impactStress ? "Y" : "N",
      eraDto.accelerators?.impactStress?.bodyPartAffected || "",
      eraDto.accelerators?.impactStress?.task || "",
      eraDto.accelerators?.impactStress ? "1" : "",
    ],
  ];

  doc.autoTable({
    startY: yPos + 4,
    head: acceleratorsHead,
    body: acceleratorsBody,
    theme: "grid",
    styles: {
      ...TABLE_STYLES.DEFAULT,
      cellPadding: 1,
    },
    headStyles: TABLE_STYLES.HEADER,

    didDrawCell: (data: any) => {
      if (
        data.section === "body" &&
        data.column.index === 1 &&
        data.cell.raw === "Y"
      ) {
        doc.setFillColor(COLORS.YELLOW[0], COLORS.YELLOW[1], COLORS.YELLOW[2]);
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "F",
        );
        doc.setTextColor(0, 0, 0);
        doc.text(
          "Y",
          data.cell.x + data.cell.width / 2,
          data.cell.y + data.cell.height / 2 + 1,
          {
            align: "center",
          },
        );
      }
    },
  });

  return doc;
};
