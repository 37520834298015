import { FC, useRef } from "react";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from "recharts";
import { Box } from "@mui/material";

export const RadarBarChart: FC<{
  chartData: {
    key: string;
    l: number;
    r: number;
  }[];
}> = ({ chartData }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={chartRef} position="relative" width={600} height={400}>
      <RadarChart width={600} height={400} outerRadius={160} data={chartData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="key" />
        <PolarRadiusAxis angle={30} domain={[0, 100]} />
        <Radar
          name="Left Video"
          dataKey="l"
          stroke="#C7B7A3"
          fill="#C7B7A3"
          fillOpacity={0.6}
        />
        <Radar
          name="Right Video"
          dataKey="r"
          stroke="#ADBC9F"
          fill="#ADBC9F"
          fillOpacity={0.6}
        />
        <Legend />
      </RadarChart>
    </Box>
  );
};
