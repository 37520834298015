import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Typography,
  Divider,
  IconButton,
  Box,
  InputAdornment,
  useTheme,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import LabelIcon from "@mui/icons-material/Label";
import DescriptionIcon from "@mui/icons-material/Description";

import { FC, useState, useEffect } from "react";
import {
  useTasksControllerUpdateUserTaskMutation,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  TaskRo,
} from "src/app/services/generatedApi";
import AutocompleteWithAdd, {
  nameIdType,
} from "../../../atoms/AutocompleteWithAdd";
import { tagModifier } from "src/components/organisms/addVideo/tagModifier";

type SaveDialogPropsType = {
  task: TaskRo;
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
};

export const EditTaskDialog: FC<SaveDialogPropsType> = ({
  task,
  open,
  handleClose,
  onSuccess,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: allTags } = useTasksControllerGetUserTagsForCurrentUserQuery();

  const [name, setName] = useState<string>(task.name || "");
  const [nameError, setNameError] = useState<string>("");
  const [tag, setTag] = useState<string | nameIdType | null>(task.tag as any);
  const [description, setDescription] = useState<string>(
    task.description || "",
  );

  const [updateTask, { isLoading }] =
    useTasksControllerUpdateUserTaskMutation();

  // Reset form when task changes
  useEffect(() => {
    if (open) {
      setName(task.name || "");
      setTag(task.tag as any);
      setDescription(task.description || "");
      setNameError("");
    }
  }, [task, open]);

  const validate = (): boolean => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Task name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    return isValid;
  };

  const save = () => {
    if (!validate()) return;

    const tagName = tagModifier(tag);
    updateTask({
      taskUpdateDto: { id: task.id, name, tag: tagName || "", description },
    })
      .unwrap()
      .then(() => {
        toast.success("Task updated successfully");
        if (onSuccess) onSuccess();
        handleClose();
      })
      .catch((error) => {
        toast.error(
          `Failed to update task: ${error.data?.message || "Unknown error"}`,
        );
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      save();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      aria-labelledby="edit-task-dialog-title"
    >
      <DialogTitle id="edit-task-dialog-title" sx={{ pr: 6 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <EditIcon color="primary" />
          <Typography variant="h6">Edit Task</Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Task Details
            </Typography>
            <TextField
              required
              autoFocus
              label="Task Name"
              placeholder="Enter task name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleKeyDown}
              error={!!nameError}
              helperText={nameError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EditIcon fontSize="small" color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />

            <AutocompleteWithAdd
              onChange={setTag}
              options={(allTags?.tags as any) || []}
              initValue={tagModifier(tag)}
              label="Tag"
              placeholder="Select or create a tag"
              startAdornment={
                <InputAdornment position="start">
                  <LabelIcon fontSize="small" color="action" />
                </InputAdornment>
              }
            />
            <FormHelperText>
              Tags help you organize related tasks
            </FormHelperText>
          </Box>

          <TextField
            multiline
            minRows={4}
            maxRows={8}
            label="Description"
            placeholder="Enter task description (optional)"
            fullWidth
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
            onKeyDown={(e) => e.key === "Tab" && e.stopPropagation()}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ alignSelf: "flex-start", mt: 1.5 }}
                >
                  <DescriptionIcon fontSize="small" color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{ px: 3, py: 2, justifyContent: isMobile ? "center" : "flex-end" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          size="large"
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={save}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          loadingPosition="start"
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
