import { jsPDF } from "jspdf";
import { loadFonts } from "src/app/utils/report";
import { COLORS } from "./constants";

export const setupPdfDocument = () => {
  const doc = new jsPDF();
  loadFonts(doc);

  doc.setFillColor(
    COLORS.DARK_BLUE[0],
    COLORS.DARK_BLUE[1],
    COLORS.DARK_BLUE[2],
  );
  doc.rect(0, 0, 210, 25, "F");

  doc.setFillColor(COLORS.EWI[0], COLORS.EWI[1], COLORS.EWI[2]);
  doc.rect(0, 23, 210, 2, "F");

  doc.addImage("/report/logo2.png", "PNG", 170, 5, 30, 12);

  doc.setTextColor(COLORS.WHITE[0], COLORS.WHITE[1], COLORS.WHITE[2]);
  doc.setFontSize(18);
  doc.setFont("roboto", "bold");
  doc.text("ERGONOMIC RISK ASSESSMENT", 15, 12);

  doc.setFontSize(11);
  doc.setFont("roboto", "normal");
  doc.text("Workplace Safety Evaluation", 15, 18);

  const today = new Date();
  const formattedDate = today.toLocaleDateString();
  doc.setFontSize(10);
  doc.text(`Report Generated: ${formattedDate}`, 15, 30);

  doc.setTextColor(COLORS.BLACK[0], COLORS.BLACK[1], COLORS.BLACK[2]);
  doc.setFontSize(10);
  doc.setFont("roboto", "normal");

  doc.addImage("/report/ewiworksLogo.png", "PNG", 10, 280, 35, 8);

  return doc;
};
