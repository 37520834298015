import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { exportChartAsImage } from "src/utils/chartsUtils";

interface ChartExportButtonProps {
  chartRef: React.RefObject<HTMLDivElement>;
  fileName?: string;
}

const ChartExportButton: React.FC<ChartExportButtonProps> = ({
  chartRef,
  fileName = "chart",
}) => {
  const handleExport = () => {
    exportChartAsImage(chartRef, fileName);
  };

  return (
    <Tooltip title="Export as image">
      <IconButton
        onClick={handleExport}
        size="small"
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          zIndex: 10,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
        className="chart-export-button"
      >
        <DownloadIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ChartExportButton;
