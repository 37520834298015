import { jsPDF } from "jspdf";
import { COLORS, OVERALL_SCORE_RANGES, TABLE_STYLES } from "../constants";
import { addSectionTitle } from "../utils";
import { EraScore } from "../types";

export const generateOverallScore = (doc: jsPDF, scores: EraScore) => {
  const yPos = addSectionTitle(
    doc,
    "Overall Assessment Score",
    doc.lastAutoTable.finalY + 11,
  );

  const scoreX = 10;
  const scoreY = yPos + 4;

  let scoreColor = COLORS.LIGHT_GREEN;
  if (scores.totalScore > 55) {
    scoreColor = COLORS.RED;
  } else if (scores.totalScore > 35) {
    scoreColor = COLORS.ORANGE;
  } else if (scores.totalScore > 20) {
    scoreColor = COLORS.YELLOW;
  }

  doc.setFillColor(scoreColor[0], scoreColor[1], scoreColor[2]);
  doc.roundedRect(scoreX, scoreY, 30, 15, 3, 3, "F");

  doc.setFont("roboto", "bold");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("OVERALL", scoreX + 15, scoreY + 5, { align: "center" });
  doc.setFontSize(14);
  doc.text(scores.totalScore.toString(), scoreX + 15, scoreY + 12, {
    align: "center",
  });

  const rangesX = scoreX + 40;
  const rangesY = scoreY + 2;

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(7);
  doc.setFont("roboto", "bold");
  doc.text("Score Ranges:", rangesX, rangesY);

  doc.setFontSize(6);
  doc.setFont("roboto", "normal");

  let currentX = rangesX;
  OVERALL_SCORE_RANGES.forEach((range, index) => {
    let color;
    const width = 8;

    if (index === 0) color = COLORS.LIGHT_GREEN;
    else if (index === 1) color = COLORS.YELLOW;
    else if (index === 2) color = COLORS.ORANGE;
    else color = COLORS.RED;

    doc.setFillColor(color[0], color[1], color[2]);
    doc.rect(currentX, rangesY + 3, width, 3, "F");
    doc.text(range, currentX + width + 2, rangesY + 5);

    currentX += 40;
  });

  doc.lastAutoTable = { finalY: scoreY + 20 };

  return doc;
};
