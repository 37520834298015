export const COLORS = {
  WHITE: [255, 255, 255],
  BLACK: [0, 0, 0],
  RED: [220, 53, 69],
  LIGHT_GRAY: [220, 220, 220],
  BACKGROUND_GRAY: [248, 249, 250],
  YELLOW: [255, 193, 7],
  LIGHT_GREEN: [40, 167, 69],
  EWI: [41, 183, 124],
  ORANGE: [253, 126, 20],
  BLUE: [0, 123, 255],
  DARK_BLUE: [52, 58, 64],
  BORDER_GRAY: [222, 226, 230],
};

export const TABLE_STYLES = {
  DEFAULT: {
    fontSize: 8,
    cellPadding: 2,
    lineColor: COLORS.BORDER_GRAY,
    lineWidth: 0.1,
    font: "roboto",
  },
  HEADER: {
    fillColor: COLORS.DARK_BLUE,
    textColor: COLORS.WHITE,
    fontStyle: "bold",
  },
  MAIN_TABLE: {
    cellPadding: 1,
    fontSize: 7,
    lineColor: COLORS.BORDER_GRAY,
    lineWidth: 0.1,
    valign: "middle",
    font: "roboto",
  },
  MAIN_TABLE_HEADER: {
    fillColor: COLORS.DARK_BLUE,
    fontSize: 8,
    textColor: COLORS.WHITE,
    fontStyle: "bold",
    halign: "center",
  },
};

export const RISK_LEVELS = {
  LOW: {
    range: "0 - 2",
    color: COLORS.LIGHT_GREEN,
    text: "Low",
    action: "No action required, monitor job",
  },
  MODERATE: {
    range: "3 - 5",
    color: COLORS.YELLOW,
    text: "Moderate",
    action: "Further investigation and action recommended",
  },
  HIGH: {
    range: "6 - 8",
    color: COLORS.ORANGE,
    text: "High",
    action: "Action strongly recommended",
  },
  VERY_HIGH: {
    range: "9 - 10",
    color: COLORS.RED,
    text: "Very High",
    action: "Immediate action strongly recommended",
  },
};

export const OVERALL_SCORE_RANGES = [
  "0 - 20 = Low",
  "21 - 35 = Moderate",
  "36 - 55 = High",
  "56 - 80 = Very High",
];
