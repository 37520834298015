import { jsPDF } from "jspdf";
import { COLORS } from "./constants";

export function boolToScore(condition: boolean, points: number = 1): string {
  return condition ? points.toString() : "-";
}

export function addSectionTitle(
  doc: jsPDF,
  title: string,
  yPosition: number,
): number {
  doc.setFillColor(
    COLORS.DARK_BLUE[0],
    COLORS.DARK_BLUE[1],
    COLORS.DARK_BLUE[2],
  );
  doc.rect(5, yPosition - 3, 3, 10, "F");

  doc.setFont("roboto", "bold");
  doc.setFontSize(11);
  doc.text(title, 12, yPosition + 1);

  doc.setDrawColor(
    COLORS.BORDER_GRAY[0],
    COLORS.BORDER_GRAY[1],
    COLORS.BORDER_GRAY[2],
  );
  doc.setLineWidth(0.5);
  doc.line(12, yPosition + 3, 200, yPosition + 3);

  doc.setFont("roboto", "normal");
  doc.setFontSize(10);

  return yPosition + 4;
}
