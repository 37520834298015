import { jsPDF } from "jspdf";
import { EraDto } from "src/app/services/generatedApi";
import { COLORS } from "../constants";

export const generateJobInfo = (doc: jsPDF, eraDto: EraDto) => {
  const startY = 30;

  const col1 = 10;
  const col2 = 40;
  const col3 = 100;
  const col4 = 130;

  const row1Y = startY + 6;
  const row2Y = row1Y + 7;
  const row3Y = row2Y + 7;

  const rectX = 5;
  const rectY = startY - 3;
  const rectWidth = 200;
  const rectHeight = row3Y - startY + 6;

  doc.setDrawColor(
    COLORS.BORDER_GRAY[0],
    COLORS.BORDER_GRAY[1],
    COLORS.BORDER_GRAY[2],
  );
  doc.setFillColor(
    COLORS.BACKGROUND_GRAY[0],
    COLORS.BACKGROUND_GRAY[1],
    COLORS.BACKGROUND_GRAY[2],
  );
  doc.roundedRect(rectX, rectY, rectWidth, rectHeight, 3, 3, "FD");

  doc.setFillColor(
    COLORS.DARK_BLUE[0],
    COLORS.DARK_BLUE[1],
    COLORS.DARK_BLUE[2],
  );
  doc.rect(rectX, rectY, rectWidth, 4, "F");
  doc.setFontSize(8);
  doc.setTextColor(COLORS.WHITE[0], COLORS.WHITE[1], COLORS.WHITE[2]);
  doc.setFont("roboto", "bold");
  doc.text("JOB INFORMATION", col1 + 2, rectY + 3);

  doc.setTextColor(COLORS.BLACK[0], COLORS.BLACK[1], COLORS.BLACK[2]);

  const addField = (
    label: string,
    value: string,
    labelX: number,
    valueX: number,
    y: number,
  ) => {
    doc.setFont("roboto", "bold");
    doc.text(label, labelX, y);
    doc.setFont("roboto", "normal");
    doc.text(value, valueX, y);
  };

  addField("Job Name:", eraDto.jobInfo.jobName || "-", col1, col2, row1Y);
  addField("Dept:", eraDto.jobInfo.department || "-", col3, col4, row1Y);

  addField("Assessor:", eraDto.jobInfo.assessor || "-", col1, col2, row2Y);
  addField(
    "Date:",
    eraDto.jobInfo.date
      ? new Date(eraDto.jobInfo.date).toLocaleDateString()
      : "-",
    col3,
    col4,
    row2Y,
  );

  addField("Shift:", eraDto.jobInfo.shift || "-", col1, col2, row3Y);
  addField(
    "Cycle Time:",
    eraDto.jobInfo.cycleTime ? `${eraDto.jobInfo.cycleTime} sec` : "-",
    col3,
    col4,
    row3Y,
  );

  doc.lastAutoTable = { finalY: row3Y + 2 };

  return doc;
};
