import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { EraDto } from "src/app/services/generatedApi";
import { TABLE_STYLES } from "../../constants";
import { addSectionTitle } from "../../utils";
import { buildMainTableData } from "./builders";
import { EraScore } from "../../types";

export function generateMainTable(
  doc: jsPDF,
  eraDto: EraDto,
  scores: EraScore,
) {
  const yPos = addSectionTitle(
    doc,
    "Step 1: Identify risk factors, score each body part, and calculate individual body part scores:",
    doc.lastAutoTable.finalY + 11,
  );

  const { mainTableHead, mainTableBody } = buildMainTableData(eraDto, scores);

  doc.autoTable({
    startY: yPos + 2,
    head: mainTableHead,
    body: mainTableBody,
    theme: "grid",
    columnStyles: {
      0: {
        fontSize: 8,
        fontStyle: "bold",
        cellWidth: 16,
        fillColor: [248, 249, 250],
      },
      2: { halign: "center" },
      4: { halign: "center" },
      6: { halign: "center" },
      7: { halign: "center" },
      9: { halign: "center" },
      10: { halign: "center" },
      12: { halign: "center" },
      13: { halign: "center" },
    },
    styles: {
      ...TABLE_STYLES.MAIN_TABLE,
      overflow: "linebreak",
      cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
    },
    headStyles: TABLE_STYLES.MAIN_TABLE_HEADER,

    rowStyles: {
      0: { fillColor: [255, 255, 255] },
      2: { fillColor: [255, 255, 255] },
      4: { fillColor: [255, 255, 255] },
    },

    bodyStyles: {
      5: { fillColor: [240, 240, 240], fontStyle: "bold" },
    },
  });

  doc.setFontSize(8);
  doc.setFont("roboto", "italic");
  doc.text(
    "Note: Higher scores indicate higher ergonomic risk potential.",
    10,
    doc.lastAutoTable.finalY + 4,
  );

  return doc;
}
