import { FC, useContext, useMemo, useRef } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SettingDto } from "src/app/services/generatedApi";
import LineChartTooltip from "src/components/organisms/charts/lineChart/typeOne/LineChartTooltip";
import CustomLegend from "src/components/organisms/charts/lineChart/typeTwo/CustomLegend";
import { TaskContext } from "src/context/TaskContext";
import { useSyncByVideo } from "./useSyncByVideo";
import { ThresholdChartLinearGradient } from "src/components/atoms/ThresholdChartLinearGradient";
import { Angle3DResultRo } from "src/types/pose";
import ChartExportButton from "src/components/atoms/ChartExportButton";
import { Box } from "@mui/material";

export const BodyPartsChart: FC<{
  selectedPart: keyof SettingDto;
  mode?: "Default" | "Alpha" | "Beta" | "Gamma";
  syncByVideo?: boolean;
  needLegend?: boolean;
}> = ({
  selectedPart,
  syncByVideo = false,
  needLegend = true,
  mode = "Default",
}) => {
  const randomNumber = useRef(Math.floor(Math.random() * 1000));
  const chartRef = useRef<HTMLDivElement>(null);

  const { duration, selectedAngles, selectedAngles3D, fps, setting } =
    useContext(TaskContext);

  const data = useMemo(
    () =>
      mode === "Default"
        ? selectedAngles.map((angles, index) => ({
            angle: angles[selectedPart],
            seconds: Math.round((index / fps) * 100) / 100,
          }))
        : selectedAngles3D.map((angles, index) => ({
            angle: angles[selectedPart as keyof Angle3DResultRo]?.[mode],
            seconds: Math.round((index / fps) * 100) / 100,
          })),
    [fps, mode, selectedAngles, selectedAngles3D, selectedPart],
  );

  const threshold = useMemo(
    () => setting[selectedPart],
    [selectedPart, setting],
  );

  const minMax = useMemo(() => {
    const numbersArray = data
      .filter(({ angle }) => angle !== undefined)
      .map(({ angle }) => angle) as number[];
    return {
      min: Math.min(...numbersArray),
      max: Math.max(...numbersArray),
    };
  }, [data]);

  const { onChange: onChangeChartSecond, dotX } = useSyncByVideo(syncByVideo);

  const dotY = dotX !== undefined && data?.[Math.round(dotX * fps)]?.angle;

  return (
    <Box ref={chartRef} position="relative" width="100%" height="100%">
      <ChartExportButton chartRef={chartRef} fileName={`${selectedPart}-chart`} />
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            left: 20,
            bottom: 17,
          }}
          onMouseMove={(e) => {
            if (!e?.isTooltipActive || !e.activePayload?.[0].payload) return;
            if (syncByVideo) {
              onChangeChartSecond(e.activePayload[0].payload.seconds);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            label={{ value: "Time", position: "bottom" }}
            type="number"
            allowDecimals={false}
            dataKey="seconds"
            name="seconds"
            unit="″"
            domain={[0, duration]}
          />
          <YAxis
            name="angle"
            type="number"
            label={{
              value: "Angle",
              angle: -90,
              position: "left",
            }}
            unit="°"
            domain={[minMax.min - 5, minMax.max + 5]}
            tickFormatter={(value) => (Math.round(100 * +value) / 100).toString()}
          />
          <Tooltip
            wrapperStyle={{
              visibility: "visible",
            }}
            content={<LineChartTooltip />}
          />
          {needLegend && (
            <Legend
              wrapperStyle={{ position: "absolute", top: "-30px" }}
              content={<CustomLegend />}
            />
          )}

          <defs>
            <ThresholdChartLinearGradient
              id={`body-part-chart-${randomNumber.current}`}
              minMax={minMax}
              threshold={threshold}
            />
          </defs>

          <Line
            type="monotone"
            name="angle"
            dataKey="angle"
            stroke={`url(#body-part-chart-${randomNumber.current})`}
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 6, fill: "black" }}
          />
          {syncByVideo && !!dotX && !!dotY && (
            <ReferenceDot
              x={dotX}
              y={dotY}
              r={3}
              fill="#000"
              stroke="white"
              strokeWidth={1}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
