import { jsPDF } from "jspdf";
import { robotoLight } from "src/assets/fonts/roboto/Roboto-Light";
import { robotoMedium } from "src/assets/fonts/roboto/Roboto-Medium";
import { robotoBold } from "src/assets/fonts/roboto/Roboto-Bold";
import { robotoBlack } from "src/assets/fonts/roboto/Roboto-Black";

export const jsPdfFonts = {
  robotoLight: { file: "robotoLight.ttf", value: robotoLight, style: "light" },
  robotoMedium: {
    file: "robotoMedium.ttf",
    value: robotoMedium,
    style: "normal",
  },
  robotoBold: { file: "robotoBold.ttf", value: robotoBold, style: "bold" },
  robotoBlack: { file: "robotoBlack.ttf", value: robotoBlack, style: "black" },
};

export const loadFonts = (pdf: jsPDF) => {
  Object.values(jsPdfFonts).forEach((font) => {
    pdf.addFileToVFS(font.file, font.value);
    pdf.addFont(font.file, "roboto", font.style);
  });
};

export const centeredText = (pdf: jsPDF, text: string, y: number) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const textWidth = pdf.getTextWidth(text);
  const x = (pageWidth - textWidth) / 2;
  pdf.text(text, x, y);
};
