import { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

type LineChartTooltipPropsType = {
  active?: boolean;
  payload?: { name: string; value: number }[];
  label?: string;
  isAssessment?: boolean;
  assessmentType?: string;
};

const LineChartTooltip: FC<LineChartTooltipPropsType> = ({
  active,
  payload,
  label,
  isAssessment = false,
  assessmentType,
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        component={Stack}
        direction="column"
        rowGap={1}
        sx={{ p: 1, mx: 4 }}
        elevation={3}
      >
        <Stack direction="row" columnGap={1}>
          <Typography color={grey[500]}>
            {isAssessment
              ? assessmentType === "ERA" ? "Risk profile" : "Score"
              : "Angle"}
          </Typography>
          <Typography>
            {payload[0].value === undefined ||
            payload[0].value === null ||
            isNaN(payload[0].value)
              ? "-"
              : payload[0].value + (isAssessment ? "" : "°")}
          </Typography>
        </Stack>
        <Stack direction="row" columnGap={1}>
          <Typography color={grey[500]}>Time</Typography>
          <Typography>{label}″</Typography>
        </Stack>
      </Paper>
    );
  }

  return null;
};

export default LineChartTooltip;
