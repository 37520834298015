import { jsPDF } from "jspdf";
import { COLORS, RISK_LEVELS, TABLE_STYLES } from "../constants";
import { addSectionTitle } from "../utils";

export const generateRiskRating = (doc: jsPDF) => {
  const yPos = addSectionTitle(
    doc,
    "Risk Rating Scale",
    doc.lastAutoTable.finalY + 11,
  );

  doc.setFontSize(8);
  doc.setFont("roboto", "italic");
  doc.text(
    "The risk rating scale helps determine the appropriate action level based on the assessed score.",
    15,
    yPos + 2,
  );

  const riskBody = Object.values(RISK_LEVELS).map((level) => [
    {
      content: level.range,
      styles: { fillColor: level.color, fontStyle: "bold" },
    },
    { content: level.text, styles: { fontStyle: "bold" } },
    level.action,
  ]);

  doc.autoTable({
    startY: yPos + 4,
    head: [["Score", "Risk Rating", "Action Level"]],
    body: riskBody,
    theme: "grid",
    styles: {
      ...TABLE_STYLES.DEFAULT,
      cellPadding: 2,
    },
    headStyles: TABLE_STYLES.HEADER,
    columnStyles: {
      0: { cellWidth: 20 },
      1: { cellWidth: 30 },
      2: { cellWidth: "auto" },
    },
  });

  const legendY = doc.lastAutoTable.finalY + 4;

  doc.setFontSize(7);
  doc.setFont("roboto", "normal");
  doc.text("Risk Level Indicator:", 10, legendY);

  const boxWidth = 8;
  const boxHeight = 3;
  const startX = 48;
  const space = 35;

  doc.setFillColor(
    COLORS.LIGHT_GREEN[0],
    COLORS.LIGHT_GREEN[1],
    COLORS.LIGHT_GREEN[2],
  );
  doc.rect(startX, legendY - 2, boxWidth, boxHeight, "F");
  doc.text("Low", startX + boxWidth + 2, legendY);

  doc.setFillColor(COLORS.YELLOW[0], COLORS.YELLOW[1], COLORS.YELLOW[2]);
  doc.rect(startX + space, legendY - 2, boxWidth, boxHeight, "F");
  doc.text("Moderate", startX + space + boxWidth + 2, legendY);

  doc.setFillColor(COLORS.ORANGE[0], COLORS.ORANGE[1], COLORS.ORANGE[2]);
  doc.rect(startX + space * 2, legendY - 2, boxWidth, boxHeight, "F");
  doc.text("High", startX + space * 2 + boxWidth + 2, legendY);

  doc.setFillColor(COLORS.RED[0], COLORS.RED[1], COLORS.RED[2]);
  doc.rect(startX + space * 3, legendY - 2, boxWidth, boxHeight, "F");
  doc.text("Very High", startX + space * 3 + boxWidth + 2, legendY);

  doc.lastAutoTable.finalY = legendY + 3;

  return doc;
};
